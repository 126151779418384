html {
  height: 100%;
}
* {
  box-sizing: border-box;
}
body {
  display: flex;
  height: 100%;
}
#root {
  display: flex;
  flex: 1;
  flex-direction: column;
  min-width: 0;
}
.ui.dimmer {
  background-color: rgba(0, 0, 0, 0.2) !important;
}
.ui.modal .close.icon {
  color: #000;
  top: -2rem;
  right: -2rem;
}
.ui.primary.button,
.ui.primary.buttons .button {
  background-color: #01aef0;
}
.ui.modal {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
